@import "../general/newTypography";
@import "../general/colors";
.containerswiper {
  display: flex;
  flex-direction: column;
  background-color: $new-background-light-blue;
}

.swiper-container {
  width: 100%;
  max-width: 1152px;
  margin: 6em auto;
  overflow: hidden;
  &__title {
    @include title-area;
    text-align: center;
    color: $new-blue;
  }
  &__subtitle {
    @include subtitle-area;
    text-align: center;
    color: $new-blue;
    margin-bottom: 2em;
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0.5rem;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  margin: 0.5rem 0;
  max-width: 300px;

  img {
    object-fit: contain;
    aspect-ratio: 16 / 9;
    margin: auto;
    width: 200px;
  }
}
